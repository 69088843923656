'use client';

import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';

function Calendar({ className, classNames, showOutsideDays = true, disabledDays, ...props }) {
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn('p-2', className)}
			classNames={{
				months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-5 sm:space-y-0',
				month: 'space-y-4',
				caption: 'flex justify-center pt-1 relative items-center',
				caption_label: 'text-base font-normal',
				nav: 'space-x-1 flex items-center',
				nav_button: cn(
					buttonVariants({ variant: 'outline' }),
					'h-eis-27 w-eis-27 rounded bg-transparent p-0 opacity-50 hover:opacity-100 text-eis-black focus-visible:!outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 '
				),
				nav_button_previous: 'absolute left-1',
				nav_button_next: 'absolute right-1',
				table: 'w-full border-collapse space-y-1',
				head_row: 'flex space-x-1 -mb-2',
				head_cell: 'text-eis-grey-60 rounded w-8 font-normal text-xs',
				row: 'flex space-x-1 w-full mt-3',
				cell: 'text-center text-xs p-0 relative [&:has([aria-selected])]:bg-eis-light-blue first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
				day: cn(
					buttonVariants({ variant: 'ghost' }),
					'text-eis-black h-8 w-8 p-0 text-xs font-normal aria-selected:opacity-100 focus-visible:!outline-none focus-visible:ring-2 focus-visible:ring-formOutlineDark focus-visible:ring-offset-2 '
				),
				day_selected:
					'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground !rounded',
				day_today: 'bg-eis-light-blue text-accent-foreground',
				day_outside: 'text-muted-foreground opacity-50',
				day_disabled: '!text-muted-foreground !opacity-50 !bg-transparent cursor-not-allowed',
				day_range_middle: 'aria-selected:bg-eis-light-blue aria-selected:text-accent-foreground',
				day_hidden: 'invisible',
				...classNames,
			}}
			components={{
				IconLeft: ({ ...props }) => <ChevronLeft className="w-eis-19 h-eis-19" />,
				IconRight: ({ ...props }) => <ChevronRight className="w-eis-19 h-eis-19" />,
			}}
			disabled={disabledDays}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export { Calendar };
